import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { Dropdown } from 'Common/Components/Dropdown';
import TableContainer, { pageLimitVaild } from 'Common/TableContainer';
import Select from 'react-select';
import { toast } from 'react-toastify';

// Icons
import { Search, Eye, Trash2, MoreHorizontal, CheckCircle, Loader, X } from 'lucide-react';
import ConfirmModal from 'Common/ConfirmModal';

import {
  fetchUserListAll,
  getUserListAll,
  userStatus
} from 'slices/thunk';
import { useDispatch } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import filterDataBySearch from 'Common/filterDataBySearch';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TableSkeleton } from 'Common/Components/TableSkeleton';
import { queryClient } from 'index';
import { findIndex } from 'lodash';
import { replaceItemAtIndex } from 'helpers/utils';
import { changeLoadingLayout } from 'slices/thunk';

const ListView = () => {
  const dispatch = useDispatch<any>();
  const [queryParams] = useSearchParams();
  const currentPage = Number(queryParams.get("page") || 1);
  const currentLimit = Number(queryParams.get("limit") || 10);
  const currentSearchQuery = queryParams.get("search") || '';
  const [currentIDSearch, SetIDSearch] = useState(0); 
  const [currentSearch, SetSearch] = useState<string>(''); 

  const [user, setUser] = useState<any>([]);
  const [ dataPage, setDataPage ] = useState<any>({});
  const [eventData, setEventData] = useState<any>();
  const { data, isLoading } = getUserListAll({ query: useQuery, limit: currentLimit, page: currentPage, search: currentSearchQuery });
  const { mutate: muateUserStatus } = useMutation({ 
    mutationFn: userStatus,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.setQueryData(['users', currentLimit, currentPage, currentSearch || currentSearchQuery], (old: any) => {
          let index = findIndex(old, (w: any) => w._id == variables.id)
          return replaceItemAtIndex(old, index, {...old[index], status: variables.status})
        })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Update Status Error");
    },
    throwOnError: false,
  })

  const { mutate: muateUserSearch } = useMutation({ 
    mutationFn: ({ limit, page, search, idSearch } : { limit: number, page: number, search: string, idSearch: number}) => fetchUserListAll({ limit, page, search }),
    onSuccess: async (data, variables ) => {
      if (variables.idSearch == currentIDSearch) {
        setUser(data.data);
        setDataPage(data);
        queryClient.setQueryData(['users', currentLimit, currentPage, variables.search], () => data.data)
        dispatch(changeLoadingLayout(false));
      }
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Search Data Error");
    },
    throwOnError: false,
  })

  useEffect(() => {
    if (data && data.data) {
      setUser(data.data);
      setDataPage(data);
    }
  }, [data]);

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const confirmToggle = () => setConfirmModal(!confirmModal);

  // Confirm Data
  const onClickDisable = (cell: any) => {
    setConfirmModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  const handleConfirm = () => {
    if (eventData) {
      dispatch(changeLoadingLayout(true));
      muateUserStatus({ id: eventData._id, status: eventData.status == 0 ? 1 : 0 })
      setConfirmModal(false);
    }
  };

  // Search Data
  const filterSearchDataServer = async (e: any) => {
    let searchText = e.target.value;
    let idSearch = Math.random();
    SetIDSearch(idSearch)
    SetSearch(searchText)
    dispatch(changeLoadingLayout(true));
    muateUserSearch({ limit: currentLimit, page: 1, search: searchText, idSearch: idSearch })
  };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case 1:
        return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />Enable</span>);
      case 0:
        return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-3 mr-1.5" />Disable</span>);
    }
  };

  const columns = useMemo(() => [
    {
      header: (
        <div className="flex items-center h-full">
          <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
        </div>
      ),
      enableSorting: false,
      id: "checkAll",
      cell: (cell: any) => {
        return (
          <div className="flex items-center h-full">
            <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
          </div>
        );
      }
    },
    {
      header: "User ID",
      accessorKey: "telegram_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <Link to={`/users/${cell.row.original._id}`}>
          <span className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id">{cell.getValue()}</span>
        </Link>
      ),
    },
    {
      header: "Name",
      accessorKey: "name",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ cell.row.original.first_name + ' ' + cell.row.original.last_name }</div>
      ),
    },
    {
      header: "Username",
      accessorKey: "username",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ '@' + cell.getValue() }</div>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <Status item={cell.getValue()} />
      ),
    },
    {
      header: "Action",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <Dropdown className="relative">
          <Dropdown.Trigger className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20" id="usersAction1">
            <MoreHorizontal className="size-3" />
          </Dropdown.Trigger>
          <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600" aria-labelledby="usersAction1">
            <li>
              <Link to={`/users/${cell.row.original._id}`} className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"><Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Overview</span></Link>
            </li>
            <li>
              <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!" onClick={() => {
                const orderData = cell.row.original;
                onClickDisable(orderData);
              }}><Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                <span className="align-middle">
                  { cell.row.original.status == 1 ? 'Disable' : 'Enable'}
                </span>
              </Link>
            </li>
          </Dropdown.Content>
        </Dropdown>
      ),
    }
  ], []
  );

  const options = [
    { value: 'Select Status', label: 'Select Status' },
    { value: '1', label: 'Enable' },
    { value: '0', label: 'Disable' },
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status') {
      setUser(user);
    } else {
      const filteredUsers = user.filter((data: any) => data.status === selectedOption.value);
      setUser(filteredUsers);
    }
  };

  if (!pageLimitVaild.includes(currentLimit)) {
    const newQuery = new URLSearchParams(queryParams);
    newQuery.set('limit', String(10));
    return <Navigate to={`?${newQuery.toString()}`} />
  }

  return (
    <React.Fragment>
      <BreadCrumb title='' pageTitle='Users' />
      <ConfirmModal show={confirmModal} onHide={confirmToggle} onConfirm={handleConfirm} />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body !py-2">
              <div className="flex items-center">
                <h6 className="text-15 grow">Users List</h6>
              </div>
            </div>
            <div className="!py-2.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for name, username, id..." autoComplete="off" onChange={(e) => filterSearchDataServer(e)} />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                  <div className="xl:col-span-2">
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      options={options}
                      isSearchable={false}
                      defaultValue={options[0]}
                      onChange={(event: any) => handleChange(event)}
                      id="choices-single-default"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body !pt-0">
              {dataPage && user && user.length > 0 ?
                <TableContainer
                  isPagination={true}
                  columns={(columns || [])}
                  data={(user || [])}
                  isSelect={true}
                  customPageSize={currentLimit}
                  ShowTotalResult={true}
                  rowCount={dataPage.totalCount}
                  pageCount={dataPage.totalPage}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-1.5 py-1.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-1.5 py-1.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
                : isLoading ? (TableSkeleton(currentLimit, columns.length)) :
                (<div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched all users We did not find any users for you request.</p>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListView;
