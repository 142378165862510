import React, { ChangeEvent } from "react";
import { BadgeCheck, Clock, Dribbble, Facebook, Github, Globe, ImagePlus, Instagram, Linkedin, Mail, MapPin, MoreHorizontal, UserCircle } from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";

// IMage
import avatar1 from "assets/images/users/avatar-1.png";

const AccountInfo = ({ className, user }: any) => {

  const [selectedImage, setSelectedImage] = React.useState<string | ArrayBuffer | null>(avatar1);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <React.Fragment>
      <div className={className}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-1 flex items-center justify-center">
            <div className="relative inline-block size-14 rounded-full shadow-md bg-slate-100 profile-user">
              { user ? (<img src={selectedImage?.toString() ?? avatar1} alt="" className="object-cover border-0 rounded-full img-thumbnail user-profile-image" />) : null }
            </div>
          </div>
          <div className="col-span-10">
            { user ? (
              <>
                <h5 className="mb-1">{user.first_name + ' ' + user.last_name} <BadgeCheck className="inline-block size-4 text-sky-500 fill-sky-100 dark:fill-custom-500/20"></BadgeCheck></h5>
                <div className="flex gap-3 mb-4">
                  <p className="text-slate-400 dark:text-zink-200"><UserCircle className="inline-block size-4 text-slate-400 dark:text-zink-200 fill-slate-100 dark:fill-zink-500 -mt-[3px]"></UserCircle> { user.username } </p>
                  <p className="text-slate-400 dark:text-zink-200"><Clock className="inline-block size-4 text-slate-400 dark:text-zink-200 fill-slate-100 dark:fill-zink-500 -mt-[3px]"></Clock> { new Date(user.createdAt).toLocaleString() }</p>
                </div>
              </>
            ) : (<div className="h-6 w-40 rounded bg-gray-100"></div>) }
          </div>
          <div className="col-span-1">
            <div className="flex gap-2 2xl:justify-end">
              <button type="button" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Hire Us</button>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default AccountInfo;