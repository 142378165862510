import React from "react";
import { Goal, Package, Wallet } from "lucide-react";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';


import miningFile from "assets/lottie/mining.lottie";

interface WalletsReport { icon: any; amount: string; description: string }

const walletsReports: WalletsReport[] = [];

const OverviewTabs = ({ miningData, wallets }: any) => {
  const Child = React.memo(() => {
    return <React.Fragment>
      <DotLottieReact
        src={miningFile}
        autoplay
        loop
      />
    </React.Fragment>;
  });

  if (wallets && walletsReports.length == 0) {
    wallets.map((w: any) => {
      walletsReports.push({
        icon: Wallet,
        amount: w.balance,
        description: w.symbol
      })
    })
    
  }
  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-5 lg:grid-cols-12">
        <div className="col-span-3 sm:col-span-5 md:col-span-3 lg:col-span-3">
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">Mining Information</h6>
              <div className="overflow-x-auto">
                <Child />
                <table className="w-full ltr:text-left rtl:ext-right">
                  <tbody>
                    <tr>
                      <th className="py-2 font-semibold ps-0" scope="row">Speed / Mbps</th>
                      <td className="py-2 text-right text-slate-500 dark:text-zink-200">
                        { miningData ? miningData.speedMbps : (<div className="h-6 w-40 rounded bg-gray-100"></div>) }
                      </td>
                    </tr>
                    <tr>
                      <th className="py-2 font-semibold ps-0" scope="row">Ton / Second</th>
                      <td className="py-2 text-right text-slate-500 dark:text-zink-200">
                        { miningData ? parseFloat(miningData.tonPerSecond).toFixed(8) : (<div className="h-6 w-40 rounded bg-gray-100"></div>) }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 sm:col-span-5 md:col-span-3 lg:col-span-3">
          <div className="card">
            <div className="card-body">
              <h6 className="mb-4 text-15">Wallet</h6>

              <div className="divide-y divide-slate-200 dark:divide-zink-500">
                { walletsReports ?
                  (walletsReports || [])?.map((item: WalletsReport, index: number) => (
                    <div className={`flex items-center gap-3 ${index === walletsReports.length - 1 ? "pt-3" : index === walletsReports.length - 2 ? "py-3" : "pb-3"}`} key={index}>
                      <div className="flex items-center justify-center size-12 rounded-full bg-slate-100 dark:bg-zink-600">
                        <item.icon className="size-5 text-slate-500 dark:text-zink-200"></item.icon>
                      </div>
                      <div>
                        <h6 className="text-lg">{item.amount}</h6>
                        <p className="text-slate-500 dark:text-zink-200">{item.description}</p>
                      </div>
                    </div>
                  )) : (
                    <>
                      <div className="h-10 w-full mb-2 rounded bg-gray-100"></div>
                      <div className="h-10 w-full mb-2 rounded bg-gray-100"></div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OverviewTabs;