import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Image
import { useQuery } from "@tanstack/react-query";
import { getUserFriends } from "slices/thunk";
import { pageLimitVaild } from "Common/TableContainer";
import { queryClient } from "index";
import { Link } from "react-router-dom";
import PaginationBasic from "Common/Components/PaginationBasic";

const Friends = ({ id }: { id: string }) => {
  const [users, setUsers] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [pageSize, setPageSize] = useState<number>(1);
  const [eventPageSize, setEventPageSize] = useState(false);
  const { data, isLoading } = getUserFriends({ query: useQuery, id: id, limit: pageLimit, page: pageSize });
  
  useEffect(() => {
    if (data && data.data)
      setUsers(data.data);
  }, [data]);

  useEffect(() => {
    if (eventPageSize) {
      queryClient.refetchQueries({ queryKey: ['user-friends', id, pageLimit, pageSize], type: 'active', exact: true })
      setEventPageSize(false);
    }
  }, [pageSize])

  return (
    <React.Fragment>
      <h5 className="mb-4 underline">Friends</h5>

      <div className="grid grid-cols-1 md:grid-cols-6 gap-x-5">
        { !isLoading ? users.map((user: any, index: number) => (
          <div key={index} className="relative card">
            <div className="card-body">
              <Link to={`/users/${user.referred_id._id}`} className="">
                <p className="absolute inline-block px-2 py-1 text-xs ltr:left-0 rtl:right-0 text-custom-600 bg-custom-100 dark:bg-custom-500/20 top-5 ltr:rounded-e rtl:rounded-l">
                    @{user.referred_id.username}
                </p>
              </Link>
              <div className="text-end">
                <h4 className="mb-2 font-semibold text-16">{user.referred_id.first_name + ' ' + user.referred_id.last_name}</h4>
              </div>
            </div>
          </div>
        )) : Array.from({ length: 12 }, (_, i) => (
          <div key={i} className="relative card">
            <div className="card-body">
              <div className="text-center">
                <a href="#!">
                  <div className="h-6 w-40 rounded bg-gray-100"></div>
                </a>
                <div className="text-slate-500 dark:text-zink-200 mt-2">
                  <div className="h-6 w-32 rounded bg-gray-100"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <PaginationBasic 
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          setEventPageSize={setEventPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          data={users}
        />
    </React.Fragment >
  );
}

export default Friends;