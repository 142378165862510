import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function getConfigSystem({ query }: { query: typeof typeUseQuery }) {
  return query({
    queryKey: ['config-system', 'all'],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/config-system`)
      return response.data;
    }(),
  });
}

export const editConfigSystem = async (values: any) => {
  const response = await axiosInstance.put(`/admin/config-system/${values._id}`, {
    "deposit_wallet": values.deposit_wallet,
    "withdraw_wallet": values.withdraw_wallet,
    "symbol_token": values.symbol_token,
    "contract_address": values.contract_address,
  })
  return response.data;
}