import { loginError, loginReset, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";
import { setAuthTokens, clearAuthTokens } from 'axios-jwt'
import { axiosInstance } from '../../api'

export interface ILoginRequest {
  username: string;
  password: string;
}

export const loginUser = (
  user: ILoginRequest,
  history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
  try {
    dispatch(loginReset())
    const response = await axiosInstance.post('/api/admin/login', user)
    if (response) {
      dispatch(loginSuccess(response.data));
      setAuthTokens({
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken
      })
      setTimeout(history("/dashboard"), 1000);
    }
  } catch (error) {
    dispatch(loginError(error));
  }
};

export const logoutUser = () => async (dispatch: Dispatch) => {
  try {
    await axiosInstance.post('/api/admin/logout')
    clearAuthTokens()
    dispatch(logoutSuccess(true));
  } catch (error) {
    dispatch(loginError(error));
  }
}