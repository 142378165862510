import React from 'react';
import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';
import { useDispatch, useSelector } from 'react-redux';
import { changeLayout, changeLoadingLayout } from 'slices/thunk';
import { LAYOUT_TYPES } from 'Common/constants/layout';
import LoadingOverlay from 'react-loading-overlay-ts';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'slices';


function App() {
  const dispatch = useDispatch<any>();
  React.useEffect(() =>   {
    dispatch(changeLoadingLayout(false));
    const handleResize = () =>   {
      if (window.innerWidth < 768)
        dispatch(changeLayout(LAYOUT_TYPES.VERTICAL))
      else
        dispatch(changeLayout(LAYOUT_TYPES.HORIZONTAL))
    };

    window.addEventListener("resize", handleResize);
    return () =>   {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const selectLayoutState = createSelector(
    (state: RootState) => state.Layout,
    (layout) => ({
        loading: layout.layoutLoading
    })
  );

  const { loading } = useSelector(selectLayoutState);
  

  return (
    <LoadingOverlay
      active={loading}
      spinner
    >
      <RouteIndex />
    </LoadingOverlay>
  );
}

export default App;
