
const filterDataBySearch = (dataList: any[], search: string, keysToSearch: string[], setDataFn: React.Dispatch<any>) => {
  const filteredData = dataList.filter((item: any) => {
    const searchMatch = !search || keysToSearch.some((key: any) => {
        const keyArr = key.split('.');
        var valuePath = item;
        for (let i = 0; i < keyArr.length; i++) {
          if (valuePath[keyArr[i]])
            valuePath = valuePath[keyArr[i]]
        }
        if (typeof(valuePath) == 'string' || typeof(valuePath) == 'number')
          return valuePath.toString().toLowerCase().includes(search.toLowerCase())
        return false;
      }
    );
    return searchMatch;
  });

  setDataFn(filteredData);
};

export default filterDataBySearch;