import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  message: string;
  token: string;
  refreshToken: string;
}

export interface LoginState {
  user: UserState | undefined;
  error: string;
  success: boolean;
  isUserLogout: boolean;
}

const initialState: LoginState = {
  user: undefined,
  error: "",
  success: false,
  isUserLogout: false
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess(state: LoginState, action: PayloadAction<UserState>) {
      state.user = action.payload;
      state.success = true;
    },
    loginError(state: LoginState, action: PayloadAction<string | any>) {
      state.user = undefined;
      state.error = action.payload;
      state.success = false;
    },
    logoutSuccess(state: LoginState, action: PayloadAction<boolean>) {
      loginReset();
      state.isUserLogout = action.payload;
    },
    loginReset(state: LoginState) {
      state.user = undefined;
      state.error = '';
      state.success = false;
    }
  },
});

export const { loginSuccess, loginError, logoutSuccess, loginReset } = loginSlice.actions;
export default loginSlice.reducer;
