import { clsx, type ClassValue } from "clsx"
import moment from "moment";
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const removeItemAtIndex = (arr: any[], index: number) => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const replaceItemAtIndex = (arr: any[], index: number, newValue: any) => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const fromNano = (nanoTON: bigint): number => {
  const TON = Number(nanoTON) / 1e9;
  return TON;
};

export const formatNumber = (value: number | bigint | null, isNano: boolean = false): string | null => {
  try {
    if (value !== null) {
      let numValue: number;

      if (typeof value === 'bigint' || isNano) {
        numValue = fromNano(BigInt(value));
        if (numValue < 1)
          return numValue.toFixed(9).replace('.', ',');
      } else {
        numValue = value;
      }
      return (numValue / 1)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        .replace(/,00$/, '');
    } else {
      return value;
    }
  } catch {
    return "0"
  }

};

export function convertMiningSpeed(speedMbps: number): string {
  const baseHashRate = speedMbps;
  const units: [number, string][] = [
    [1, "H/s"],
    [1e3, "KH/s"],
    [1e6, "MH/s"],
    [1e9, "GH/s"],
    [1e12, "TH/s"],
    [1e15, "PH/s"],
  ];

  let value = baseHashRate * 1e6;
  let unitIndex = 0;

  while (value >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  const roundedValue = Number(value.toPrecision(2)) || 0;
  return `${roundedValue} ${units[unitIndex][1]}`;
}
