// dashboard
import Analytics from "pages/Dashboards/Analytics";

// auth
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
import Maintenance from "pages/AuthenticationInner/Maintenance";

// Users
import UserListView from "pages/Users/ListView";
import Account from "pages/Users/Details";

// Configs
import ConfigSystem from "pages/Configs/system";
import ConfigMining from "pages/Configs/mining";
import ConfigQuests from "pages/Configs/quests";
import ConfigReferralQuests from "pages/Configs/referral.quests";
import ConfigReferralRewards from "pages/Configs/referral.rewards";

// Transaction
import Transaction from "pages/Transaction/ListView";

// Quest
import Quest from "pages/Quest/ListView";

// referral
import ReferralQuest from "pages/Referral/Quest";
import ReferralReward from "pages/Referral/Reward";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Analytics },
  { path: "/dashboard", component: Analytics },

  // Users
  { path: "/users", component: UserListView },
  { path: "/users/:id", component: Account },

  // profile
  { path: "/logout", component: Logout },

  // config
  { path: "/config/system", component: ConfigSystem },
  { path: "/config/mining", component: ConfigMining },
  { path: "/config/quests", component: ConfigQuests },
  { path: "/config/referral-quests", component: ConfigReferralQuests },
  { path: "/config/referral-rewards", component: ConfigReferralRewards },
  
  // transaction
  { path: "/transaction", component: Transaction },

  // transaction
  { path: "/quest", component: Quest },

  // referral
  { path: "/referral-quest", component: ReferralQuest },
  { path: "/referral-reward", component: ReferralReward },
];

const publicRoutes = [
  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  { path: "/pages-404", component: Pages404 },

  // // Maintenance
  { path: "/pages-maintenance", component: Maintenance },

  // authentication
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes };
