import { IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor, getBrowserLocalStorage } from 'axios-jwt'
import axios, { AxiosError } from 'axios'

const BASE_URL = process.env.REACT_APP_API

export const axiosInstance = axios.create({ baseURL: BASE_URL })
const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
  try {
    const response = await axios.post(`${BASE_URL}/api/admin/renew-token`, { refreshToken: refreshToken })
    return response.data.token
  } catch {
    localStorage.clear();
    return '';
  }
}

axiosInstance.interceptors.response.use((response: any) => {
  return response;
}, (error: AxiosError) => {
  if (error && 
    error.response && 
    error.response.data && 
    error.response.data&&
    typeof(error.response.data) == 'object' &&
    (error.response.data as any)['message'] &&
    (error.response.data as any)['message'] == 'TOKEN_EXPIRED'
  ) {
    localStorage.clear();
    window.location.replace('/login');
  }
  return Promise.reject(error);
})

const getStorage = getBrowserLocalStorage
applyAuthTokenInterceptor(axiosInstance, { requestRefresh, getStorage })