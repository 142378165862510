import React from "react";
import AccountInfo from "./AccountInfo";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import OverviewTabs from "./OverviewTabs";
import Transaction from "./Transaction";
import ReferalQuests from "./ReferalQuests";
import ReferalRewards from "./ReferalRewards";
import ProjectsTabs from "./ProjectsTabs";
import Friends from "./Friends";
import { getUser } from "slices/thunk";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, Navigate, useParams } from 'react-router-dom';

const Account = () => {
  document.title = "Account | Tonfly";
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: user, isLoading, isError } = getUser({ query: useQuery, id: id });

  React.useEffect(() => {
    if (isError) {
      navigate('/users')
    }
  }, [isError]);

  if (!id) {
    return <Navigate to={`/users`} />
  }

  return (
    <React.Fragment>
      <Tab.Container defaultActiveKey="overviewTabs">
        <div className="mt-1 -ml-3 -mr-3 rounded-none card">
          <AccountInfo user={user ? user.user : undefined} className="card-body !px-2.5"/>
          <div className="card-body !px-2.5 !py-0">
            <Nav className="flex flex-wrap w-full text-sm font-medium text-center nav-tabs">
              { !user ? (
                Array.from({ length: 4 }, (_, i) => (
                  <Nav.Item eventKey="overviewTabs" className="group" key={`overviewTabs-${i}`}>
                    <div className="h-6 w-16 rounded bg-gray-100 mx-5 my-2"></div>
                  </Nav.Item>
                ))
              ) : (
                <>
                  <Nav.Item eventKey="overviewTabs" className="group">
                    <span data-tab-toggle data-target="overviewTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px] cursor-pointer">Overview</span>
                  </Nav.Item>
                  <Nav.Item eventKey="documentsTabs" className="group">
                    <span data-tab-toggle data-target="documentsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px] cursor-pointer">Transaction</span>
                  </Nav.Item>
                  <Nav.Item eventKey="referalQuestsTabs" className="group">
                    <span data-tab-toggle data-target="referalQuestsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px] cursor-pointer">Referal Quests</span>
                  </Nav.Item>
                  <Nav.Item eventKey="referalRewardsTabs" className="group">
                    <span data-tab-toggle data-target="referalRewardsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px] cursor-pointer">Referal Rewards</span>
                  </Nav.Item>
                  <Nav.Item eventKey="friendsTabs" className="group">
                    <span data-tab-toggle data-target="friendsTabs" className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border-b border-transparent group-[.active]:text-custom-500 dark:group-[.active]:text-custom-500 group-[.active]:border-b-custom-500 dark:group-[.active]:border-b-custom-500 hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px] cursor-pointer">Friends</span>
                  </Nav.Item>
                </>
              )}
            </Nav>
          </div>
        </div>
        <Tab.Content className="tab-content">
          <Tab.Pane eventKey="overviewTabs" id="overviewTabs">
            <OverviewTabs miningData={user ? user.miningInfo : undefined} wallets={user ? user.wallets : undefined} />
          </Tab.Pane>
          <Tab.Pane eventKey="documentsTabs" id="documentsTabs">
            <Transaction id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="referalQuestsTabs" id="referalQuestsTabs">
            <ReferalQuests id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="referalRewardsTabs" id="referalRewardsTabs">
            <ReferalRewards id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey="friendsTabs" id="friendsTabs">
            <Friends id={id} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </React.Fragment>
  );
}

export default Account;