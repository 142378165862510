import React, { useEffect, useMemo, useState } from "react";
import { BadgeInfo } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { getUserReferalRewards } from "slices/thunk";
import TableContainer from "Common/TableContainer";
import { TableSkeleton } from "Common/Components/TableSkeleton";
import { formatNumber } from "helpers/utils";

const ReferalRewards = ({ id }: { id: string }) => {
  const [data, setData] = useState<any>([]);
  const { data: dataList, isLoading } = getUserReferalRewards({ query: useQuery, id });
  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  const columns = useMemo(() => [
    {
      header: (
        <div className="flex items-center h-full">
          <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
        </div>
      ),
      enableSorting: false,
      id: "checkAll",
      cell: (cell: any) => {
        return (
          <div className="flex items-center h-full">
            <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
          </div>
        );
      }
    },
    {
      header: "Column 1",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
    {
      header: "Column 2",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
    {
      header: "Column 3",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
    {
      header: "Column 4",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
    {
      header: "Column 5",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
    {
      header: "Column 6",
      accessorKey: "_id",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div></div>
      ),
    },
  ], []);
  
  return (
    <React.Fragment>
      <div className="flex items-center gap-3 mb-4">
        <h5 className="underline grow">Referal Rewards</h5>
      </div>
      <div className="card">
        <div className="card-body !pt-0">
          {data && data.length >= 0 ?
            <TableContainer
              isPagination={false}
              columns={(columns || [])}
              data={(data || [])}
              divclassName="-mx-5 -mb-5 overflow-x-auto"
              tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
              theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
              thclassName="px-1.5 py-1.5 first:pl-5 last:pr-5 font-semibold"
              tdclassName="px-1.5 py-1.5 first:pl-5 last:pr-5"
              PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            />
            : isLoading ? (TableSkeleton(10, columns.length)) :
            (<div className="noresult">
              <div className="py-6 text-center">
                <BadgeInfo className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched all data We did not find any data for you request.</p>
              </div>
            </div>)}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReferalRewards;