import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export async function fetchUserListAll({ limit, page, search } : { limit: number, page: number, search: string | '' }) {
  const response = await axiosInstance.get(`/admin/users?limit=${limit}&page=${page}&keyword=${search}`)
  var data = response.data.data;
  data.users.data.map((w: any) => {
    return { ...w, fullname: w.first_name + ' ' + w.last_name }
  })
  return data.users;
}

export function getUserListAll({ query, limit, page, search }: { query: typeof typeUseQuery, limit: number, page: number, search: string | '' }) {
  return query({
    queryKey: ['users', limit, page, search],
    queryFn: () => async function() {
      return fetchUserListAll({ limit, page, search });
    }(),
  });
}

export function getUser({ query, id }: { query: typeof typeUseQuery, id: string | undefined }) {
  return query({
    queryKey: ['user', id],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/users/${id}`)
      return response.data.data;
    }(),
  });
}

export const userStatus = async ({ id, status } : { id: string, status: number }) => {
  const response = await axiosInstance.put(`/admin/users/${id}`, { 'status': status })
  return response;
}

export function getUserFriends({ query, id, limit, page }: { query: typeof typeUseQuery, id: string, limit: number, page: number }) {
  return query({
    queryKey: ['user-friends', id, limit, page],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/users/${id}/friends?limit=${limit}&page=${page}`)
      return response.data.data.friends;
    }(),
  });
}

export function getUserTransaction({ query, id, limit, page }: { query: typeof typeUseQuery, id: string, limit: number, page: number }) {
  return query({
    queryKey: ['user-transaction', id, limit, page],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/users/${id}/transaction-histories?limit=${limit}&page=${page}`)
      return response.data.data.transaction;
    }(),
  });
}

export function getUserReferalQuests({ query, id }: { query: typeof typeUseQuery, id: string }) {
  return query({
    queryKey: ['user-referal-quests', id],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/users/${id}/referal-quests`)
      return response.data.data;
    }(),
  });
}

export function getUserReferalRewards({ query, id }: { query: typeof typeUseQuery, id: string }) {
  return query({
    queryKey: ['user-referal-rewards', id],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/users/${id}/referal-rewards`)
      return response.data.data;
    }(),
  });
}