import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function getConfigReferralRewards({ query }: { query: typeof typeUseQuery }) {
  return query({
    queryKey: ['config-referral-rewards', 'all'],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/config-referral-rewards`)
      return response.data;
    }(),
  });
}

export const editConfigReferralRewards = async (values: any) => {
  const response = await axiosInstance.put(`/admin/config-referral-rewards/${values._id}`, {
    "level": parseInt(values.level),
    "reward": parseInt(values.reward),
    "symbol": values.symbol,
    "bonus_speed": parseInt(values.bonus_speed),
  })
  return response.data;
}