export function TableRowSkeleton(column: number) {
  return Array.from({ length: column }, (_, i) => (
    <td className="whitespace-nowrap px-3 py-3" key={'column-skeleton' + i}>
      <div className="h-6 w-16 rounded bg-gray-100"></div>
    </td>
  ))
}

export function TableSkeleton(row: number, column: number) {
  return (
    <div className="mt-6 flow-root">
      <div className="inline-block min-w-full align-middle">
        <div className="rounded-lg bg-gray-50 p-2 md:pt-0">
          <table className="min-w-full text-gray-900">
            <tbody className="bg-white">
              { 
                Array.from({ length: row }, (_, i) => (
                  <tr key={'row-skeleton' + i} className="w-full border-b border-gray-100 last-of-type:border-none [&:first-child>td:first-child]:rounded-tl-lg [&:first-child>td:last-child]:rounded-tr-lg [&:last-child>td:first-child]:rounded-bl-lg [&:last-child>td:last-child]:rounded-br-lg">
                    {TableRowSkeleton(column)}
                  </tr>
                )) 
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
