import React, { useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Navigate, useSearchParams } from 'react-router-dom';
import TableContainer, { pageLimitVaild } from 'Common/TableContainer';

// Icons
import { Search } from 'lucide-react';

import { changeLoadingLayout, fetchReferralReward, getReferralReward} from 'slices/thunk';
import { useDispatch } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TableSkeleton } from 'Common/Components/TableSkeleton';
import { convertMiningSpeed, formatNumber } from 'helpers/utils';
import { queryClient } from 'index';

const ListView = () => {
  const dispatch = useDispatch<any>();
  const [queryParams] = useSearchParams();
  const currentPage = Number(queryParams.get("page") || 1);
  const currentLimit = Number(queryParams.get("limit") || 10);
  const currentSearchQuery = queryParams.get("search") || '';
  const [currentIDSearch, SetIDSearch] = useState(0); 
  const [currentSearch, SetSearch] = useState<string>(''); 

  const [dataList, setDataList] = useState<any>([]);
  const [dataPage, setDataPage] = useState<any>({});
  const { data, isLoading } = getReferralReward({ query: useQuery, limit: currentLimit, page: currentPage, search: currentSearchQuery });
  const { mutate: muateDataSearch } = useMutation({ 
    mutationFn: ({ limit, page, search, idSearch } : { limit: number, page: number, search: string, idSearch: number}) => fetchReferralReward({ limit, page, search }),
    onSuccess: async (data, variables ) => {
      if (variables.idSearch == currentIDSearch) {
        setDataList(data.data);
        setDataPage(data);
        queryClient.setQueryData(['referral-quest', currentLimit, currentPage, variables.search], () => data.data)
        dispatch(changeLoadingLayout(false));
      }
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Search Data Error");
    },
    throwOnError: false,
  })

  useEffect(() => {
    if (data && data.data) {
      setDataList(data.data);
      setDataPage(data);
    }
  }, [data]);
  
  // Search Data
  const filterSearchDataServer = async (e: any) => {
    let searchText = e.target.value;
    let idSearch = Math.random();
    SetIDSearch(idSearch)
    SetSearch(searchText)
    dispatch(changeLoadingLayout(true));
    muateDataSearch({ limit: currentLimit, page: 1, search: searchText, idSearch: idSearch })
  };

  const columns = useMemo(() => [
    {
      header: "Level",
      accessorKey: "config_referral_reward_id.level",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ formatNumber(cell.getValue()) }</div>
      ),
    },
    {
      header: "Reward",
      accessorKey: "config_referral_reward_id.reward",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ formatNumber(cell.getValue(),true) }</div>
      ),
    },
    {
      header: "Bonus Speed",
      accessorKey: "config_referral_reward_id.bonus_speed",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ convertMiningSpeed(cell.getValue()) }</div>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ cell.getValue() }</div>
      ),
    },
    {
      header: "User",
      accessorKey: "user_id.username",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{ '@' + cell.getValue() } ({ cell.row.original.user_id.first_name + ' ' + cell.row.original.user_id.last_name })</div>
      ),
    },
  ], []);

  if (!pageLimitVaild.includes(currentLimit)) {
    const newQuery = new URLSearchParams(queryParams);
    newQuery.set('limit', String(10));
    return <Navigate to={`?${newQuery.toString()}`} />
  }

  return (
    <React.Fragment>
      <BreadCrumb title='' pageTitle='Transaction' />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body !py-2">
              <div className="flex items-center">
                <h6 className="text-15 grow">Referral Reward History</h6>
              </div>
            </div>
            <div className="!py-2.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for text..." autoComplete="off" onChange={(e) => filterSearchDataServer(e)} />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body !pt-0">
            {dataPage && dataList && dataList.length > 0 ?
                <TableContainer
                  isPagination={true}
                  columns={(columns || [])}
                  data={(dataList || [])}
                  isSelect={true}
                  customPageSize={currentLimit}
                  ShowTotalResult={true}
                  rowCount={dataPage.totalCount}
                  pageCount={dataPage.totalPage}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-1.5 py-1.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-1.5 py-1.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
                : isLoading ? (TableSkeleton(currentLimit, columns.length)) :
                (<div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched all data We did not find any datas for you request.</p>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListView;
