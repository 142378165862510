import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BadgeInfo, CheckCircle, Pencil, Plus, Search, ShieldCheck, ShieldX, Trash2, X } from "lucide-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { changeLoadingLayout, addConfigQuests, editConfigQuests, configQuestsStatus, deleteConfigQuests, getConfigQuests } from "slices/thunk";
import TableContainer from "Common/TableContainer";
import { TableSkeleton } from "Common/Components/TableSkeleton";
import { formatNumber, removeItemAtIndex, replaceItemAtIndex } from "helpers/utils";
import { Tooltip } from 'react-tooltip'
import { queryClient } from "index";
import { findIndex } from "lodash";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Modal from 'Common/Components/Modal';
import ConfirmModal from "Common/ConfirmModal";
import Select from 'react-select';
import Cleave from "cleave.js/react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import filterDataBySearch from 'Common/filterDataBySearch';

const ConfigQuests = () => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const confirmToggle = () => setConfirmDeleteModal(!confirmDeleteModal);

  const { data: dataList, isLoading } = getConfigQuests({ query: useQuery });

  // Update Status
  const { mutate: muateStatus } = useMutation({ 
    mutationFn: configQuestsStatus,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.setQueryData(['config-quests', 'all'], (old: any) => {
          let index = findIndex(old, (w: any) => w._id === variables.id)
          return replaceItemAtIndex(old, index, {...old[index], status: variables.status})
        })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Update Data Error");
    },
    throwOnError: false,
  })

  // Delete Quests
  const { mutate: muateDelete } = useMutation({ 
    mutationFn: deleteConfigQuests,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.setQueryData(['config-quests', 'all'], (old: any) => {
          let index = findIndex(old, (w: any) => w._id === variables.id)
          return removeItemAtIndex(old, index)
        })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Delete Data Error");
    },
    throwOnError: false,
  })

  // Edit Quests
  const { mutate: muateEdit } = useMutation({ 
    mutationFn: editConfigQuests,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.setQueryData(['config-quests', 'all'], (old: any) => {
          let index = findIndex(old, (w: any) => w._id === variables._id)
          return replaceItemAtIndex(old, index, data)
        })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Update Data Error");
    },
    throwOnError: false,
  })

  // Add Quests
  const { mutate: muateAdd } = useMutation({ 
    mutationFn: addConfigQuests,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.refetchQueries({ queryKey: ['config-quests', 'all'], type: 'active', exact: true })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Create Data Error");
    },
    throwOnError: false,
  })

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [ 'name', 'description', 'type', 'symbol'];
    filterDataBySearch(dataList, search, keysToSearch, setData);
  };

  const updateStatus = (row: any) => {
    dispatch(changeLoadingLayout(true));
    muateStatus({ id: row._id, status: row.status === 'ACTIVE' ? 'DISABLE' : 'ACTIVE' })
  };

  const onClickDelete = (cell: any) => {
    setConfirmDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  const handleConfirmDelete = () => {
    if (eventData) {
      dispatch(changeLoadingLayout(true));
      muateDelete({ id: eventData._id })
      setConfirmDeleteModal(false);
    }
  };

  const options = [
    { value: 'Select Status', label: 'Select Status' },
    { value: 'ACTIVE', label: 'Active' },
    { value: 'DISABLE', label: 'Disable' },
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === 'Select Status') {
      setData(dataList);
    } else {
      const filteredData = dataList.filter((data: any) => selectedOption.value === 'ACTIVE' ? data.status === 'ACTIVE' : data.status !== 'ACTIVE');
      setData(filteredData);
    }
  };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case "ACTIVE":
        return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-5 mr-1.5" />Active</span>);
      default:
        return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-5 mr-1.5" />Disable</span>);
    }
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (eventData && eventData.name) || '',
      description: (eventData && eventData.description) || '',
      type: (eventData && eventData.type) || '',
      link: (eventData && eventData.link) || '',
      symbol: (eventData && eventData.symbol) || '',
      reward: (eventData && eventData.reward) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Description"),
      type: Yup.string().required("Please Enter Type"),
      symbol: Yup.string().required("Please Enter Symbol"),
      reward: Yup.string().required("Please Enter Reward"),
    }),

    onSubmit: (values) => {
      toggle();
      dispatch(changeLoadingLayout(true));
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        muateEdit(updateData)
      } else {
        muateAdd(values);
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  const columns = useMemo(() => [
    {
      header: (
        <div className="flex items-center h-full">
          <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
        </div>
      ),
      enableSorting: false,
      id: "checkAll",
      cell: (cell: any) => {
        return (
          <div className="flex items-center h-full">
            <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
          </div>
        );
      }
    },
    {
      header: "Name",
      accessorKey: "name",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{cell.getValue()}</div>
      ),
    },
    {
      header: "Description",
      accessorKey: "description",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{cell.getValue()}</div>
      ),
    },
    {
      header: "Type",
      accessorKey: "type",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{cell.getValue()}</div>
      ),
    },
    {
      header: "Link",
      accessorKey: "link",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{cell.getValue()}</div>
      ),
    },
    {
      header: "Symbol",
      accessorKey: "symbol",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{cell.getValue()}</div>
      ),
    },
    {
      header: "Reward",
      accessorKey: "reward",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{formatNumber(cell.getValue(),true)}</div>
      ),
    },
    {
      header: "Created",
      accessorKey: "createdAt",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{new Date(cell.getValue()).toLocaleString() }</div>
      ),
    },
    {
      header: "Status",
      accessorKey: "status",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <Status item={cell.getValue()} />
      ),
    },
    {
      header: "Action",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <div className="flex cursor-pointer">
          { cell.row.original.status === 'ACTIVE' ? 
            (
              <>
                <ShieldX data-tooltip-id="disableData" className="size-5 mr-1.5" onClick={() => {
                  const data = cell.row.original;
                  updateStatus(data)
                }}/>
                <Tooltip id="disableData" place="top" content="Disable Configs" />
              </>)
            : 
            (
              <>
                <ShieldCheck data-tooltip-id="activeData" className="size-5 mr-1.5" onClick={() => {
                  const data = cell.row.original;
                  updateStatus(data)
                }}/>
                <Tooltip id="activeData" place="top" content="Active Configs" />
              </>
            )
          }
          <Pencil className="size-5 mr-1.5" onClick={() => {
            const data = cell.row.original;
            handleUpdateDataClick(data);
          }} />
          <Trash2 className="size-5 mr-1.5" onClick={() => {
            const orderData = cell.row.original;
            onClickDelete(orderData);
          }} />
        </div>
      ),
    }
  ], []);
  
  return (
    <React.Fragment>
      <ConfirmModal show={confirmDeleteModal} onHide={confirmToggle} onConfirm={handleConfirmDelete} />
      <ToastContainer closeButton={false} limit={1} />
      <div className="flex items-center gap-3 mb-4">
        <h5 className="underline grow">Config Quests</h5>
        <div className="shrink-0">
          <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add</span></button>
        </div>
      </div>
      <div className="card">
        <div className="card-body !pt-0">
          <div className="!py-2.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for text..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
                <div className="xl:col-span-2">
                  <Select
                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    options={options}
                    isSearchable={false}
                    defaultValue={options[0]}
                    onChange={(event: any) => handleChange(event)}
                    id="choices-single-default"
                  />
                </div>
              </div>
            </form>
          </div>

          {data && data.length >= 0 ?
            <TableContainer
              isPagination={false}
              columns={(columns || [])}
              data={(data || [])}
              divclassName="-mx-5 -mb-5 overflow-x-auto"
              tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
              theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
              thclassName="px-1.5 py-1.5 first:pl-5 last:pr-5 font-semibold"
              tdclassName="px-1.5 py-1.5 first:pl-5 last:pr-5"
              PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            />
            : isLoading ? (TableSkeleton(10, columns.length)) :
            (<div className="noresult">
              <div className="py-6 text-center">
                <BadgeInfo className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched all data We did not find any data for you request.</p>
              </div>
            </div>)}
        </div>

        {/* Modal  */}
        <Modal show={show} onHide={toggle} id="defaultModal" modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
          <Modal.Header className="flex items-center justify-between p-2 border-b dark:border-zink-300/20"
            closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
            <Modal.Title className="text-16">{!!isEdit ? "Edit" : "Add"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] !pt-2 p-4 overflow-y-auto">
            <form action="#!" onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Name</label>
                <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter name"
                  name="name"
                  onChange={validation.handleChange}
                  value={validation.values.name || ""}
                />
                {validation.touched.name && validation.errors.name ? (
                  <p className="text-red-400">{validation.errors.name}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Description</label>
                <textarea rows={3} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter Description"
                  name="description"
                  onChange={validation.handleChange}
                  value={validation.values.description || ""}
                />
                {validation.touched.description && validation.errors.description ? (
                  <p className="text-red-400">{validation.errors.description}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Type</label>
                <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter Description"
                  name="type"
                  onChange={validation.handleChange}
                  value={validation.values.type || ""}
                />
                {validation.touched.type && validation.errors.type ? (
                  <p className="text-red-400">{validation.errors.type}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Link</label>
                <textarea rows={3} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter Description"
                  name="link"
                  onChange={validation.handleChange}
                  value={validation.values.link || ""}
                />
                {validation.touched.link && validation.errors.link ? (
                  <p className="text-red-400">{validation.errors.link}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Symbol</label>
                <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter Description"
                  name="symbol"
                  onChange={validation.handleChange}
                  value={validation.values.symbol || ""}
                />
                {validation.touched.symbol && validation.errors.symbol ? (
                  <p className="text-red-400">{validation.errors.symbol}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Reward</label>
                <Cleave
                  placeholder="Enter numeral"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  name="reward"
                  onChange={validation.handleChange}
                  value={validation.values.reward || ""}
                />
                {validation.touched.reward && validation.errors.reward ? (
                  <p className="text-red-400">{validation.errors.reward}</p>
                ) : null}
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={toggle}>Cancel</button>
                <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                  {!!isEdit ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default ConfigQuests;