import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Tạo một phiên bản mới của QueryClient
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 300000, // Lưu trữ dữ liệu trong cache trong 5 phút (tính bằng mili giây)
      staleTime: 60000, // Coi dữ liệu là cũ sau 1 phút (tính bằng mili giây)
      refetchOnWindowFocus: false, // Tắt tự động refetch khi cửa sổ được focus
      retry: 3, // Thử lại truy vấn lỗi tối đa 3 lần
    },
    mutations: {
      throwOnError: true, // Throw error nếu mutation gặp lỗi
    },
  },
});

const persistConfig = {
 key: 'root',
 storage: storage,
 stateReconciler: autoMergeLevel2,
 blacklist: ['Login', 'Users']
};

const pReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);
const store = configureStore({ 
  reducer: pReducer, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
  }),
  devTools: true
});
const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
