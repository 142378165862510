import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { statisticNewUsers } from 'slices/thunk';
import moment from 'moment';
import useChartColors from 'Common/useChartColors';
import ReactApexChart from 'react-apexcharts';
import { queryClient } from 'index';


const DatetimeChart = ({ chartId, data }: any) => {
  const chartColors = useChartColors(chartId);
  //Spline
  const series = [{
    name: 'New Users',
    data: data
  }];
  var options : any = {
  chart: {
    id: 'area-datetime',
    type: 'area',
    height: 350,
    zoom: {
      autoScaleYaxis: true
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  xaxis: {
    type: 'datetime',
    min: new Date(data && data[0] && data[0][0] ? data[0][0] : null).getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    }
  },
  colors: chartColors,
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100]
    }
  },
};

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series || []}
        data-chart-colors='["bg-custom-500"]'
        id={chartId}
        className="apex-charts"
        type='area'
        height={350}
      />
    </React.Fragment>
  );
};

const NewUsers = () => {
  const [from, setFrom] = useState<any>(0);
  const [to, setTo] = useState<any>(0);
  const [chartData, setChartData] = useState<number[]>([]);
  const [activeTime, setActiveTime] = useState('');
  const { data } = statisticNewUsers({ query: useQuery, from: from, to: to });

  const handleThisWeek = () => {
    moment.updateLocale('en', {
      week : {
        dow : 1
      }
    });
  
    setFrom(moment().startOf('week').format('DD/MM/YYYY'));
    setTo(moment().endOf('week').format('DD/MM/YYYY'));
    setActiveTime('this_week')
  }

  const handleThisMonth = () => {
    setFrom(moment().startOf('month').format('DD/MM/YYYY'));
    setTo(moment().endOf('month').format('DD/MM/YYYY'));
    setActiveTime('this_month')
  }

  const handleSixMonth = () => {
    setFrom(moment().subtract(6, 'months').format('DD/MM/YYYY'));
    setTo(moment().format('DD/MM/YYYY'));
    setActiveTime('six_months')
  }

  const handleOneYear = () => {
    setFrom(moment().subtract(1, 'years').format('DD/MM/YYYY'));
    setTo(moment().format('DD/MM/YYYY'));
    setActiveTime('one_year')
  }

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ['statistic-new-users', from, to], type: 'active', exact: true })
  }, [from, to])

  useEffect(() => {
    handleThisWeek();
  }, [])

  useEffect(() => {
    if (data)
      setChartData(data.map((w: any) => [moment(w.date, 'DD/MM/YYYY').valueOf(), w.total]));
  }, [data])

  return (
    <React.Fragment>
      <div className="order-7 col-span-12 2xl:order-1 card 2xl:col-span-6">
        <div className="card-body">
          <h6 className="mb-4 text-15">New Users</h6>
          <div className="flex flex-wrap gap-2 toolbar align-items-start justify-content-center">
            <button type="button" className={`px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white` + (activeTime == 'this_week' ? ' active' : '')}  id="this_week" onClick={handleThisWeek}>
              This Week
            </button>
            <button type="button" className={`px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white` + (activeTime == 'this_month' ? ' active' : '')}id="this_month" onClick={handleThisMonth}>
              This Month
            </button>
            <button type="button" className={`px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white` + (activeTime == 'six_months' ? ' active' : '')}  id="six_months" onClick={handleSixMonth}>
              6 Month
            </button>
            <button type="button" className={`px-2 py-1.5 text-xs text-custom-500 btn bg-custom-100 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:ring active:ring-custom-100 dark:bg-custom-500/20 dark:text-custom-500 dark:hover:bg-custom-500 dark:hover:text-white dark:focus:bg-custom-500 dark:focus:text-white dark:active:bg-custom-500 dark:active:text-white dark:ring-custom-400/20 [&.active]:bg-custom-500 [&.active]:text-white` + (activeTime == 'one_year' ? ' active' : '')}  id="one_year" onClick={handleOneYear}>
              One Year
            </button>
          </div>
          <DatetimeChart chartId="dateTimeChart-new-users" data={chartData} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewUsers;
