import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export async function fetchReferralQuest({ limit, page, search } : { limit: number, page: number, search: string | '' }) {
  const response = await axiosInstance.get(`/admin/referral-quest-histories?limit=${limit}&page=${page}&keyword=${search}`)
  return response.data.data.histories;
}

export function getReferralQuest({ query, limit, page, search }: { query: typeof typeUseQuery, limit: number, page: number, search: string | '' }) {
  return query({
    queryKey: ['referral-quest', limit, page, search],
    queryFn: () => async function() {
      return fetchReferralQuest({ limit, page, search })
    }(),
  });
}