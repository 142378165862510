import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function getConfigQuests({ query }: { query: typeof typeUseQuery }) {
  return query({
    queryKey: ['config-quests', 'all'],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/config-quests`)
      return response.data;
    }(),
  });
}

export const configQuestsStatus = async ({ id, status } : { id: string, status: string }) => {
  const response = await axiosInstance.put(`/admin/config-quests/${id}`, { 'status': status })
  return response;
}

export const deleteConfigQuests = async ({ id } : { id: string }) => {
  const response = await axiosInstance.delete(`/admin/config-quests/${id}`)
  return response;
}

export const editConfigQuests = async (values: any) => {
  const response = await axiosInstance.put(`/admin/config-quests/${values._id}`, {
    "name": values.name,
    "description": values.description,
    "type": values.type,
    "reward": values.reward,
    "symbol": values.symbol,
    "link": values.link
  })
  return response.data;
}

export const addConfigQuests = async (values: any) => {
  const response = await axiosInstance.post(`/admin/config-quests`, {
    "name": values.name,
    "description": values.description,
    "type": values.type,
    "status": "ACTIVE",
    "reward": parseInt(values.reward),
    "symbol": values.symbol,
    "link": values.link
  })
  return response.data;
}