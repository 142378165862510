import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import NewUsers from './NewUsers';
import TopUsers from './TopUsers';
import Transaction from './Transaction';

const Analytics = () => {

  return (
    <React.Fragment>
      <BreadCrumb title='Analytics' pageTitle='Dashboards' />
      <div className="grid grid-cols-12 gap-x-5">
        <NewUsers />
        <TopUsers title='Top Miners' path='miners' limit={7} field='total_minted'/>
        <TopUsers title='Top Deposit' path='deposit' limit={7} field='total_deposit'/>
        <Transaction />
        <TopUsers title='Top Referrals' path='referrals' limit={7} field='total_referral'/>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
