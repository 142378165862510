import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function statisticNewUsers({ query, from, to }: { query: typeof typeUseQuery, from: string, to: string }) {
  return query({
    queryKey: ['statistic-new-users', from, to],
    queryFn: () => async function() {
      if (from && to) {
        const response = await axiosInstance.get(`/admin/statistic/new-users?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`)
        return response.data;
      } else {
        return null;
      }
    }(),
  });
}

export function statisticTransaction({ query, from, to }: { query: typeof typeUseQuery, from: string, to: string }) {
  return query({
    queryKey: ['statistic-transaction', from, to],
    queryFn: () => async function() {
      if (from && to) {
        const response = await axiosInstance.get(`/admin/statistic/transaction?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&symbol=TON&type=IN`)
        return response.data;
      } else {
        return null;
      }
    }(),
  });
}

export function statisticTopUsers({ query, type, limit }: { query: typeof typeUseQuery, type: string, limit: number }) {
  return query({
    queryKey: ['top-users', type, limit],
    queryFn: () => async function() {
      if (limit) {
        const response = await axiosInstance.get(`admin/statistic/top-${type}?limit=${limit}`)
        return response.data;
      } else {
        return null;
      }
    }(),
  });
}