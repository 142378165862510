import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function getConfigMining({ query }: { query: typeof typeUseQuery }) {
  return query({
    queryKey: ['config-mining', 'all'],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/config-mining`)
      return response.data;
    }(),
  });
}

export const editConfigMining = async (values: any) => {
  const response = await axiosInstance.put(`/admin/config-mining/${values._id}`, {
    "ghs_rate": parseInt(values.ghs_rate),
    "default_ghs": parseInt(values.default_ghs),
    "ton_rate": values.ton_rate.toString(),
    "min_withdraw_amount": parseInt(values.min_withdraw_amount),
  })
  return response.data;
}