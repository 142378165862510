import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BadgeInfo, Pencil, Search } from "lucide-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { changeLoadingLayout, editConfigMining, getConfigMining } from "slices/thunk";
import TableContainer from "Common/TableContainer";
import { TableSkeleton } from "Common/Components/TableSkeleton";
import { formatNumber, replaceItemAtIndex } from "helpers/utils";
import { queryClient } from "index";
import { findIndex } from "lodash";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Modal from 'Common/Components/Modal';
import Cleave from "cleave.js/react";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import filterDataBySearch from 'Common/filterDataBySearch';

const ConfigMining = () => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { data: dataList, isLoading } = getConfigMining({ query: useQuery });

  // Edit Quests
  const { mutate: muateEdit } = useMutation({ 
    mutationFn: editConfigMining,
    onSuccess: async (data, variables) => {
      if (data) {
        queryClient.setQueryData(['config-mining', 'all'], (old: any) => {
          let index = findIndex(old, (w: any) => w._id === variables._id)
          return replaceItemAtIndex(old, index, data)
        })
      }
      dispatch(changeLoadingLayout(false));
    },
    onError: async (error, variables) => {
      dispatch(changeLoadingLayout(false));
      toast.error("Update Data Error");
    },
    throwOnError: false,
  })

  useEffect(() => {
    setData(dataList);
  }, [dataList]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [ 'ghs_rate', 'default_ghs', 'ton_rate', 'min_withdraw_amount', 'max_mining_hour'];
    filterDataBySearch(dataList, search, keysToSearch, setData);
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ghs_rate: (eventData && eventData.ghs_rate) || '',
      default_ghs: (eventData && eventData.default_ghs) || '',
      ton_rate: (eventData && eventData.ton_rate) || '',
      min_withdraw_amount: (eventData && eventData.min_withdraw_amount) || '',
    },
    validationSchema: Yup.object({
      ghs_rate: Yup.string().required("Please Enter GHS Rate"),
      default_ghs: Yup.string().required("Please Enter Default GHS"),
      ton_rate: Yup.string().required("Please Enter TON Rate"),
      min_withdraw_amount: Yup.string().required("Please Enter Min Withdraw Amount"),
    }),

    onSubmit: (values) => {
      toggle();
      dispatch(changeLoadingLayout(true));
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        muateEdit(updateData)
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  const columns = useMemo(() => [
    {
      header: "GHS Rate",
      accessorKey: "ghs_rate",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{formatNumber(cell.getValue())}</div>
      ),
    },
    {
      header: "Default GHS",
      accessorKey: "default_ghs",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{formatNumber(cell.getValue())}</div>
      ),
    },
    {
      header: "TON Rate",
      accessorKey: "ton_rate",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{isNaN(parseFloat(cell.getValue())) ? cell.getValue() : parseFloat(cell.getValue()).toFixed(8)}</div>
      ),
    },
    {
      header: "Min Withdraw Amount",
      accessorKey: "min_withdraw_amount",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{formatNumber(cell.getValue(),true)}</div>
      ),
    },
    {
      header: "Updated",
      accessorKey: "updatedAt",
      enableColumnFilter: false,
      cell: (cell: any) => (
        <div>{new Date(cell.getValue()).toLocaleString() }</div>
      ),
    },
    {
      header: "Action",
      enableColumnFilter: false,
      enableSorting: true,
      cell: (cell: any) => (
        <div className="flex cursor-pointer">
          <Pencil className="size-5 mr-1.5" onClick={() => {
            const data = cell.row.original;
            handleUpdateDataClick(data);
          }} />
        </div>
      ),
    }
  ], []);
  
  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <div className="flex items-center gap-3 mb-4">
        <h5 className="underline grow">Configs Mining</h5>
      </div>
      <div className="card">
        <div className="card-body !pt-0">
          <div className="!py-2.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-2">
                  <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for text..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
              </div>
            </form>
          </div>

          {data && data.length >= 0 ?
            <TableContainer
              isPagination={false}
              columns={(columns || [])}
              data={(data || [])}
              divclassName="-mx-5 -mb-5 overflow-x-auto"
              tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
              theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
              thclassName="px-1.5 py-1.5 first:pl-5 last:pr-5 font-semibold"
              tdclassName="px-1.5 py-1.5 first:pl-5 last:pr-5"
              PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            />
            : isLoading ? (TableSkeleton(10, columns.length)) :
            (<div className="noresult">
              <div className="py-6 text-center">
                <BadgeInfo className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">We've searched all data We did not find any data for you request.</p>
              </div>
            </div>)}
        </div>

        {/* Modal  */}
        <Modal show={show} onHide={toggle} id="defaultModal" modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
          <Modal.Header className="flex items-center justify-between p-2 border-b dark:border-zink-300/20"
            closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
            <Modal.Title className="text-16">{!!isEdit ? "Edit" : "Add"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] !pt-2 p-4 overflow-y-auto">
            <form action="#!" onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">GHS Rate</label>
                <Cleave
                  placeholder="Enter numeral"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  name="ghs_rate"
                  onChange={validation.handleChange}
                  value={validation.values.ghs_rate || ""}
                />
                {validation.touched.ghs_rate && validation.errors.ghs_rate ? (
                  <p className="text-red-400">{validation.errors.ghs_rate}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Default GHS</label>
                <Cleave
                  placeholder="Enter numeral"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  name="default_ghs"
                  onChange={validation.handleChange}
                  value={validation.values.default_ghs || ""}
                />
                {validation.touched.default_ghs && validation.errors.default_ghs ? (
                  <p className="text-red-400">{validation.errors.default_ghs}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">TON Rate</label>
                <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter Description"
                  name="ton_rate"
                  onChange={validation.handleChange}
                  value={validation.values.ton_rate || ""}
                />
                {validation.touched.ton_rate && validation.errors.ton_rate ? (
                  <p className="text-red-400">{validation.errors.ton_rate}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <label className="inline-block mb-2 text-base font-medium">Min Withdraw Amount</label>
                <Cleave
                  placeholder="Enter numeral"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  name="min_withdraw_amount"
                  onChange={validation.handleChange}
                  value={validation.values.min_withdraw_amount || ""}
                />
                {validation.touched.min_withdraw_amount && validation.errors.min_withdraw_amount ? (
                  <p className="text-red-400">{validation.errors.min_withdraw_amount}</p>
                ) : null}
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={toggle}>Cancel</button>
                <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                  {!!isEdit ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default ConfigMining;