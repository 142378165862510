import { Bolt, Gift, ListMinus, MonitorDot, UserRound, UsersRound, WalletCards } from "lucide-react";

const menuData: any = [
  {
    label: 'menu',
    isTitle: true,
  },
  {
    id: "dashboard",
    label: 'Dashboards',
    link: "/#",
    icon: <MonitorDot />,
  },
  {
    id: 'users',
    label: 'Users',
    icon: <UserRound />,
    link: "/users",
  },
  {
    id: 'configs',
    label: 'Configs',
    icon: <Bolt />,
    subItems: [
      {
        id: 'configs-system',
        label: 'System',
        link: '/config/system',
        parentId: 'configs'
      },
      {
        id: 'configs-mining',
        label: 'Mining',
        link: '/config/mining',
        parentId: 'configs'
      },
      {
        id: 'configs-quests',
        label: 'Quests',
        link: '/config/quests',
        parentId: 'configs'
      },
      {
        id: 'configs-referral-quests',
        label: 'Referral Quests',
        link: '/config/referral-quests',
        parentId: 'configs'
      },
      {
        id: 'configs-referral-rewards',
        label: 'Referral Rewards',
        link: '/config/referral-rewards',
        parentId: 'configs'
      },
    ]
  },
  {
    id: 'transaction',
    label: 'Transaction',
    icon: <WalletCards />,
    link: "/transaction",
  },
  {
    id: 'quests',
    label: 'Quest',
    icon: <ListMinus />,
    link: "/quest",
  },
  {
    id: 'referral-quest',
    label: 'Referral Quest',
    icon: <UsersRound />,
    link: "/referral-quest",
  },
  {
    id: 'referral-reward',
    label: 'Referral Reward',
    icon: <Gift />,
    link: "/referral-reward",
  },
];

export { menuData };