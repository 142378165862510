import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from 'slices/api';
import { type useQuery as typeUseQuery } from '@tanstack/react-query';

export function getConfigReferralQuests({ query }: { query: typeof typeUseQuery }) {
  return query({
    queryKey: ['config-referral-quests', 'all'],
    queryFn: () => async function() {
      const response = await axiosInstance.get(`/admin/config-referral-quests`)
      return response.data;
    }(),
  });
}

export const deleteConfigReferralQuests = async ({ id } : { id: string }) => {
  const response = await axiosInstance.delete(`/admin/config-referral-quests/${id}`)
  return response;
}

export const addConfigReferralQuests = async (values: any) => {
  const response = await axiosInstance.post(`/admin/config-referral-quests`, {
    "name": values.name,
    "target_number": parseInt(values.target_number),
    "symbol": values.symbol,
    "reward": parseInt(values.reward),
    "bonus_speed": parseInt(values.bonus_speed),
  })
  return response.data;
}