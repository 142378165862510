import { useQuery } from '@tanstack/react-query';
import { formatNumber } from 'helpers/utils';
import React from 'react';
import { statisticTopUsers } from 'slices/thunk';

const TopUsers = ({ title, path, limit, field }: any) => {
  const { data } = statisticTopUsers({ query: useQuery, type: path, limit });
  const isNano = field === "total_minted" || field === "total_deposit";
  return (
    <React.Fragment>
      <div className="order-8 col-span-12 2xl:order-1 card 2xl:col-span-3">
        <div className="card-body">
          <div className="flex items-center mb-3">
            <h6 className="grow text-15">{title}</h6>
          </div>
          <ul className="divide-y divide-slate-200 dark:divide-zink-500">
            {
              (data || [])?.map((item: any) => {
                return (
                  <li key={item._id} className="flex items-center gap-3 py-2 first:pt-0 last:pb-0">
                    <div className="grow">
                      <h6 className="font-medium">@{item.user.username}</h6>
                      <p className="text-slate-500 dark:text-zink-200">{item.user.first_name + ' ' + item.user.last_name}</p>
                    </div>
                    <div className="shrink-0">
                      <h6>{formatNumber(item[field], isNano)}</h6>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopUsers;
