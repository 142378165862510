import { pageLimitVaild } from "Common/TableContainer";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

const PaginationBasic = ({ pageLimit, setPageLimit, setEventPageSize, pageSize, setPageSize, data }: any) => {
  return (
    <React.Fragment>
      <div className="flex flex-col items-center md:flex-row">
        <div className="mb-4 grow md:mb-0">
          <div className="self-center col-span-12 lg:col-span-6">
            <label>Show
              <select name="basic_tables_length" aria-controls="basic_tables"
                className="px-3 py-1 ml-2 pr-[1.5rem] form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 inline-block w-auto"
                defaultValue={pageLimit}
                onChange={(event: any) => {
                  setEventPageSize(true);
                  setPageLimit(event.target.value);
                }}>
                {pageLimitVaild.map(pageLimit => (
                  <option key={pageLimit} value={pageLimit}>
                    {pageLimit}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <ul className="flex flex-wrap items-center gap-2 shrink-0">
          <li>
            <span className={`cursor-pointer inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-not-allowed ${pageSize === 1 && "disabled"}`} onClick={(event) => setPageSize(pageSize - 1)}>
              <ChevronLeft className="size-4 mr-1 rtl:rotate-180"></ChevronLeft> Prev</span>
          </li>
          <li>
            <span className={`inline-flex items-center justify-center bg-white dark:bg-zink-700 size-8 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-100 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-white dark:[&.active]:text-white [&.active]:bg-custom-500 dark:[&.active]:bg-custom-500 [&.active]:border-custom-500 dark:[&.active]:border-custom-500 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-not-allowed active`}>{pageSize}</span>
          </li>
          <li>
            <span className={`cursor-pointer inline-flex items-center justify-center bg-white dark:bg-zink-700 h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200 dark:border-zink-500 text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500 hover:bg-custom-50 dark:hover:bg-custom-500/10 focus:bg-custom-50 dark:focus:bg-custom-500/10 focus:text-custom-500 dark:focus:text-custom-500 [&.active]:text-custom-500 dark:[&.active]:text-custom-500 [&.active]:bg-custom-50 dark:[&.active]:bg-custom-500/10 [&.active]:border-custom-50 dark:[&.active]:border-custom-500/10 [&.active]:hover:text-custom-700 dark:[&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 dark:[&.disabled]:text-zink-300 [&.disabled]:cursor-not-allowed ${(!data || data.length === 0) && "disabled"}`} onClick={() => setPageSize(pageSize + 1)}>
              Next <ChevronRight className="size-4 ml-1 rtl:rotate-180"></ChevronRight> </span>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default PaginationBasic;