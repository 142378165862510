import React from "react";
import { Facebook, Github, Loader2, Mail, Twitter } from "lucide-react";

// Formik validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { createSelector } from 'reselect';
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link, Navigate } from "react-router-dom";
import { RootState } from "slices";
import { isLoggedIn } from "axios-jwt";

const Login = (props: any) => {
  document.title = "Login | Tonfly";
  
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch<any>();
  const selectLogin = createSelector(
    (state: RootState) => state.Login,
    (login) => ({
      success: login.success,
      error: login.error
    })
  )

  const { success, error } = useSelector(selectLogin)

  const validation: any = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: localStorage.getItem("remember") || '',
      password: '',
      remember: localStorage.getItem("remember") ? true : false
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      if (values.remember) {
        localStorage.setItem("remember", values.username);
      } else {
        localStorage.removeItem("remember");
      }
      setIsLoading(true);
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  React.useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
    return () => {
      bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
    }
  }, []);

  React.useEffect(() => {
    setIsLoading(false);
  }, [error]);

  if (isLoggedIn()) {
    return <Navigate to={{ pathname: "/dashboard" }} />;
  }

  return (
    <React.Fragment>
      <div className="relative">
        <AuthIcon />
    
        <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
          <div className="!px-10 !py-12 card-body">
            <Link to="/">
            <img src={logoLight} alt="" className="hidden h-6 mx-auto dark:block" />
            <img src={logoDark} alt="" className="block h-6 mx-auto dark:hidden" />
            </Link>
      
            <div className="mt-8 text-center">
            <h4 className="mb-1 text-custom-500 dark:text-custom-500">Welcome Back !</h4>
            <p className="text-slate-500 dark:text-zink-200">Sign in to continue to Tonfly.</p>
            </div>
      
            <form className="mt-10" id="signInForm"
              onSubmit={(event: any) => {
                event.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {success && <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50" id="successAlert">
                You have <b>successfully</b> signed in.
              </div>}
              {error && <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50" id="successAlert">
                You have <b>failed</b> signed in.
              </div>}
              <div className="mb-3">
                <label htmlFor="username" className="inline-block mb-2 text-base font-medium">UserName</label>
                <input
                type="text"
                id="username"
                name="username"
                autoComplete="username"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter username"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.username || ""}
                />
                {validation.touched.username && validation.errors.username ? (
                <div id="username-error" className="mt-1 text-sm text-red-500">{validation.errors.username}</div>
                ) : null}
        
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="inline-block mb-2 text-base font-medium">Password</label>
                <input
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                ) : null}
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <input 
                    id="remember" 
                    className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                    type="checkbox"
                    onChange={validation.handleChange}
                    defaultChecked={validation.values.remember}/>
                  <label htmlFor="checkboxDefault1" className="inline-block text-base font-medium align-middle cursor-pointer">Remember me</label>
                </div>
              </div>
      
              <div className="mt-10">
                <button type="submit" className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 disabled:bg-slate-500 disabled:text-slate-50 disabled:border-slate-500 disabled:flex disabled:items-center disabled:justify-center" disabled={isLoading} >
                { isLoading ? (<>
                  <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                  Processing...
                </>
                ) : (<span>Sign In</span>)
                }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Login);